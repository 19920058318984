import * as React from "react";
import Layout from "../components/layout";
import { graphql } from "gatsby";
import { MDXRenderer } from "gatsby-plugin-mdx";
import PageHeading from "../components/page_heading";
import ToC from "../components/toc";
import SideBar from "../components/sidebar";
import { Grid, StyledCheeseImage ,} from "../components/cheese_gallery";
import { getImage } from "gatsby-plugin-image";

const CheesePost = ({ data }) => {
  const toc = <ToC headings={data.mdx.headings} className="foo"></ToC>;
  const imageNodes = data.mdx.frontmatter.extra_images;
  const gatsbyImages = imageNodes.map(node => <StyledCheeseImage image={getImage(node)}/>);

  return (
    <>
      <SideBar elements={toc} />
      <Layout iconName="cheese">
        <PageHeading
          headingText={data.mdx.frontmatter.title}
          subHeadingText={data.mdx.frontmatter.date}
        />
        <Grid>{gatsbyImages}</Grid>
        <MDXRenderer>{data.mdx.body}</MDXRenderer>
      </Layout>
    </>
  );
};

export const query = graphql`
  query ($id: String) {
    mdx(id: { eq: $id }) {
      id
      headings {
        value
        depth
      }
      slug
      frontmatter {
        title
        extra_images {
          childImageSharp {
            gatsbyImageData
          }
        }
      }
      body
    }
  }
`;

export default CheesePost;
