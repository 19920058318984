import * as React from "react";
import styled from "styled-components";
import { GatsbyImage, getImage } from "gatsby-plugin-image";
import { Link } from "gatsby";

const Grid = styled.div`
  display: flex;
  align-items: center;
  flex-wrap: wrap;
  justify-content: center;
  gap: 10px;
`;

const CheeseTitle = styled.h2`
  text-align: center;
  text-decoration:none;
  color:#555555;
`;

const StyledCheeseImage = styled(GatsbyImage)`
  width: 300px;
  transition: all 0.3s ease;
  border-radius: 10%;
  :hover {
    transform: scale(1.1);
    z-index: 10;
  }
`;

const StyledLink = styled(Link)`
  text-decoration:none;

`

const CheeseItem = ({node})  =>{
  // TODO: check how to "build" a node from the directory, joining the string "front_image" to get the gatsby image data
  const image_data = node.childMdx.frontmatter.extra_images.filter(image => image.name == "front_image")[0]
  const image = <StyledCheeseImage image={getImage(image_data)} />;
  let title = node.childMdx.frontmatter.title;
  return (
    <StyledLink to={`/cheese/${node.childMdx.slug}`}>
      <CheeseTitle>{title}</CheeseTitle>
      {image}
    </StyledLink>
  );
}
export {Grid, CheeseItem, StyledCheeseImage};